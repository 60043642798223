@use 'bootstrap' as bs;

html {
  scroll-behavior: smooth;
}

body {
  letter-spacing: 0.02rem;
}

input[type='search']::-webkit-search-cancel-button {
  display: none;
}

input[type='search']:focus::-webkit-search-cancel-button {
  display: none;
}

.ais-CurrentRefinements-item {
  .ais-CurrentRefinements-label,
  .ais-CurrentRefinements-category {
    display: block;
    text-align: left;
  }

  .ais-CurrentRefinements-label {
    padding-bottom: 0.5rem;
  }
}

// Source: https://cdn.jsdelivr.net/npm/instantsearch.css@7/themes/algolia-min.css

// Range slider
.ais-RangeSlider .rheostat {
  overflow: visible;
  margin-top: 40px;
  margin-bottom: 40px;
}

.ais-RangeSlider .rheostat-background {
  height: 6px;
  top: 0;
  width: 100%;
}

.ais-RangeSlider .rheostat-handle {
  margin-left: -12px;
  top: -7px;
}

.ais-RangeSlider .rheostat-background {
  position: relative;
  background-color: #fff;
  border: 1px solid #aaa;
}

.ais-RangeSlider .rheostat-progress {
  position: absolute;
  top: 1px;
  height: 4px;
  background-color: bs.$primary;
}

.rheostat-handle {
  position: relative;
  z-index: 1;
  width: 17px;
  height: 17px;
  background-color: #fff;
  border: 1px solid #333;
  border-radius: 50%;
  cursor: -webkit-grab;
  cursor: grab;
}

.rheostat-marker {
  margin-left: -1px;
  position: absolute;
  width: 1px;
  height: 5px;
  background-color: #aaa;
}

.rheostat-marker--large {
  height: 9px;
}

.rheostat-value {
  padding-top: 15px;
}

.rheostat-tooltip,
.rheostat-value {
  margin-left: 50%;
  position: absolute;
  text-align: center;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.rheostat-tooltip {
  top: -22px;
}
