@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;1,400;1,500&family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$font-family-sans-serif: 'Barlow', sans-serif;

$primary: #6e7b91;
$secondary: #330c2f;
$gray-100: #e2e5e9;
$gray-200: #c5cad3;
$gray-300: #a8b0bd;
$gray-400: #8b95a7;
$gray-500: #6e7b91;
$gray-600: #586274;
$gray-700: #424a57;
$gray-800: #2c313a;
$gray-900: #16191d;
$black: #0b0c0e;

$light: $gray-100;

$text-muted: $gray-500;

$link-decoration: underline;
$link-color: $gray-500;

$input-font-weight: 400;
//$input-border-width: 1px;
//$input-border-color: lighten($primary, 10%);
//$input-focus-border-color: $black;
$input-btn-focus-box-shadow: 0 0 0 0.2rem rgba(51, 12, 47, 0.251);

$component-active-bg: $secondary;
$custom-select-color: $gray-800;
$custom-select-bg: $gray-100;
$custom-select-border-width: 0;
$input-btn-padding-x-sm: 0.9rem;
$input-btn-padding-y-sm: 0.4rem;

$input-padding-x: 1.3rem;
$input-padding-y: 0.8rem;

$font-size-base: 0.95rem;
$input-font-size: 1.4rem;

$input-font-weight: 400;
$headings-font-weight: 700;
$display1-weight: 400;
$display2-weight: 400;
$display3-weight: 400;
$display4-weight: 400;

$badge-font-size: 75%;
$badge-font-weight: 300;
$badge-pill-padding-x: 1.5em;
$badge-padding-y: 0.75em;

$enable-rounded: false;
$enable-responsive-font-sizes: true;

@import 'bootstrap/scss/bootstrap';

.font-letter-spacing-tight {
  letter-spacing: -0.05rem;
}

input[type='search'] {
  letter-spacing: -0.05em;
}

a {
  text-decoration: underline !important;
}

.text-dark-2 {
  color: $gray-600;
}

.navbar-toggler-icon {
  background-size: 75% 75%;
}

// For loading indicator svg
.stroke-primary {
  stroke: $primary;
}

.border-width-2 {
  border-width: 2px;
}

.navbar-light .navbar-toggler-icon {
  background-image: url('~images/refine_icon.svg');
}
