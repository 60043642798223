@use 'bootstrap' as bs;
@use 'app';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow: hidden;
}

body * {
  //border: 1px solid red;
}

.search-result-card {
  min-height: 100px;

  @media (min-width: 768px) {
    min-height: 175px;
  }
}

@media (min-width: 768px) {
  #filters-section.d-md-block {
    display: block !important;
  }
}

a.clickable-search-term {
  color: unset;
  text-decoration-line: underline;
  text-decoration-color: bs.$gray-500;
  -webkit-text-decoration-line: underline;
  -webkit-text-decoration-color: bs.$gray-500;
  cursor: pointer;
}

mark {
  color: unset;
  background-color: rgba(bs.$gray-200, 0.7);
  padding: 0 0.08em;
}

.ais-SearchBox-loadingIndicator {
  position: absolute;
  top: 21px;
  right: 60px;
}

@media (max-width: 1200px) {
  h1,
  .h1 {
    font-size: calc(1.6rem + 1.2vw);
  }
}

#sort-by {
  max-width: 185px;
}

.ais-RefinementList-searchBox {
  max-width: 250px;
}

#map,
#geosearch-ui {
  height: 100vh;

  @media (max-width: 768px) {
    height: 50vh;
  }
}

#map {
  .ais-GeoSearch {
    position: relative;
  }

  .ais-GeoSearch,
  .ais-GeoSearch-map {
    height: 100%;
  }

  .ais-GeoSearch-control {
    position: absolute;
    top: 0.8rem;
    left: 3.75rem;
  }

  .ais-GeoSearch-reset {
    position: absolute;
    bottom: 1.25rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}
